body, #main-contents, #center-content-container {
  height: 100%;
}
@media only screen and (min-width: 600px) {
  #center-content-container {
      height: 80%;
  }
}
#center-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
#center-contents {
    padding: 32px;
}
.search-example {
  display: inline-block;
}
